# TODO: Move this to a stimulus controller and get rid of the jQuery.
import Handsontable from "handsontable"

$(document).on "turbo:load", ->
  sheet = $("#invite-sheet")
  columns = [
    { type: "text",         data: "emails" }
    { type: "text",         data: "first_name" }
    { type: "text",         data: "last_name" }
    { type: "autocomplete", data: "type", source: sheet.data("roles"), strict: true, allowInvalid: false }
    { type: "date",         data: "start_date", dateFormat: "MM/DD/YYYY" }
    { type: "date",         data: "birthdate", dateFormat: "MM/DD/YYYY"  }
    { type: "autocomplete", data: "department_name", source: sheet.data("departments"), strict: false, allowInvalid: true }
    { type: "text",         data: "title"  }
  ]
  if sheet.length
    window.inviteSheet = new Handsontable(sheet[0], {
      rowHeaders: false,
      colHeaders: true,
      colHeaders: ["Email*", "First name*", "Last name*", "Role*", "Start date<span class='material-icons-round text-xs ml-1'>today</span>", "Birthdate<span class='material-icons-round text-xs ml-1'>today</span>", "Department", "Title"]
      columns: columns
      height: "auto",
      licenseKey: "non-commercial-and-evaluation",
      stretchH: "all",
    });

$(document).on "click", ".company-invites-action:not([disabled])", (e) ->
  e.preventDefault()
  schema  = window.inviteSheet.getSettings().columns
  rows    = window.inviteSheet.getData().filter((v) -> v[0] && v[0].length > 0)
  users   = []
  modal   = $(@).parents(".modal-control")[0].modal
  roles   = { "Employee": "Roles::Employee", "Manager": "Roles::Manager", "Admin": "Roles::Admin" }
  for row in rows
    user = {}
    for cell, i in row
      user[schema[i]["data"]] = (roles[cell] || cell)
    users.push(user)
  $.ajax
    url:      $(@).attr("href")
    data:     { user_data: users }
    dataType: "json"
    method:   "post"
    success: ->
      modal.close()
      window.inviteSheet.clear()
      window.inviteSheet.loadData([])
      window.inviteSheet.deselectCell()
    error: (xhr, status, error) ->
      modal.close()
      for row, i in xhr.responseJSON.errors
        for attr, error of row
          col  = window.inviteSheet.propToCol(attr)
          cell = window.inviteSheet.getCell(i, col)
          cell.setAttribute("title", error)
          window.inviteSheet.setCellMeta(i, col, "valid", false)
      window.inviteSheet.render()
